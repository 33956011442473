<template>
  <div class="application">
    <div class="banner_wrap">
      <img :src="banner" alt="" />
      <div class="banner-title">连接市场供求，赋能AI生态</div>
    </div>
    <div class="swiper-container" v-loading="loading">
      <div class="swiper-wrapper">
        <div v-for="(item,index) in publiceList" :key="`${item.picurl}-${index}`" class='swiper-slide publiceItem'>
          <img @error="imageLoadError" class="app-img" :src="item.picurl" />
          <div class='publiceItem-title' v-if="item.sceneTags">{{ item.sceneTags[0] }}
          </div>
          <div :data-card="JSON.stringify(item)" @click.stop="goExperience(item)" class='publiceItem-btn background'
            :style="{ 'backgroundImage' : `url(${ require('@/assets/images/publiceBtn.png') })`}"></div>
          <div class='publiceItem-company background'>{{ item.companyName }}</div>
        </div>
      </div>
      <div class="arrow_wrap arrow_left">
        <img class="leftArrow" src="@/assets/images/left_arrow.png" alt="">
      </div>
      <div class="arrow_wrap arrow_right">
        <img class="rightArrow" src="@/assets/images/right_arrow.png" alt="">
      </div>

    </div>

    <common-footer></common-footer>
  </div>
</template>
<script>
/* eslint-disable */
import api from '@/api/api'
import commonFooter from '@/components/commonFooter'

// import vueSeamlessScroll from 'vue-seamless-scroll'
// import Swiper from 'swiper';
import 'swiper/swiper-bundle.min.css';
import Swiper from 'swiper/swiper-bundle.min.js';
import defaultImage from '@/assets/images/defaultImg.svg';
export default {
  components: {
    commonFooter,
    // vueSeamlessScroll
  },

  data () {
    return {
      banner: require('@/assets/images/banner1.png'),
      publiceList: [
        {}
      ],
      loading: false,
      defaultImage,
    }
  },
  computed: {
  },

  mounted () {
    this.getInititalApp()
  },
  methods: {
    //获取首发数字人app
    async getInititalApp () {
      this.loading = true;
      try {
        const res = await api.queryInititalApp({
          appType: 3,
        });
        if (res.code == 200) {
          this.publiceList = res.data.list.map(item => {
            return {
              ...item,
              picurl: window.location.origin + '/ecol/app/image?path=/' + item.advertisingMap
            }
          });
          this.$nextTick(() => {
            new Swiper('.swiper-container', {
              loop: true,
              speed: 1000,
              slidesPerView: 3,
              slidesOffsetBefore: 130,
              preloadImages: false,
              navigation: {
                nextEl: '.arrow_right',
                prevEl: '.arrow_left',
              },
              autoplay: {
                delay: 1500,
                pauseOnMouseEnter: true,
                disableOnInteraction: false,
              },
              on: {
                click: (_, e) => {
                  let cardDataJSON = e.target.getAttribute('data-card')
                  let cardData = JSON.parse(cardDataJSON)
                  console.log(cardData)
                  if(cardData){
                    this.goExperience(cardData)
                  }
                }
              }
            });
          });
        }
      } catch (error) {
        console.error('请求失败', error);
      } finally {
        this.loading = false;
      }
    },
    goExperience (item) {
      this.$router.push(`/appExperience/${item.id}/${item.companyName}/${item.appType}`)
    },
    imageLoadError (event) {
      event.target.src = this.defaultImage;
    },
  }
}
</script>
<style lang="scss">
.application {
  position: relative;
  background: #ffffff;
  width: 100%;
  .banner_wrap {
    position: relative;
    img {
      width: 1920px;
      height: 240px;
    }
    .banner-title {
      position: absolute;
      font-size: 69.33px;
      font-family: PangMenZhengDao;
      font-weight: normal;
      text-align: justify;
      color: #ffffff;
      line-height: 78.67px;
      letter-spacing: 4.67px;
      text-shadow: 2.67px 5.33px 0px 0px rgba(62, 111, 250, 0.5);
      z-index: 11;
      top: 85px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.background {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.swiper-container {
  width: 1798px;
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 55px;
  position: relative;
  box-sizing: border-box;
  .arrow_wrap {
    position: absolute;
    z-index: 999999;
    width: 50px;
    height: 100%;
    top: 0px;
    background: #fff;
    display: flex;
    align-items: center;
    img {
      cursor: pointer;
    }
  }
  .arrow_left {
    left: 0;
  }
  .arrow_right {
    right: 0px;
    display: flex;
    justify-content: end;
  }

  .publiceItem {
    max-width: 550px;
    min-height: 450px;
    background: #f5f9ff;
    border-radius: 8px;
    box-shadow: 1px 1.73px 24px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    cursor: pointer;
    background-size: 100%;
    margin-right: 24px;
    &-title {
      display: inline-block;
      text-align: center;
      padding: 2px;
      font-size: 16px;
      font-weight: 700;
      color: #fff;
      letter-spacing: 2px;
      font-family: "黑体";
      margin: 10px;
      background: #5693fb;
      position: relative;
      z-index: 2;
    }
    &-btn {
      width: 176px;
      height: 80px;
      position: absolute;
      top: 70%;
      left: calc(50% - 88px);
      transition: opacity 0.8s ease-in-out;
      display: none;
      z-index: 2;
    }

    &-company {
      position: absolute;
      background: linear-gradient(0deg, #81b0ff 0%, #2774f7 100%), #2774f7;
      padding: 6px 12px;
      border-radius: 6px;
      color: #fff;
      letter-spacing: 1px;
      bottom: 0;
      right: 0;
      font-size: 12px;
      z-index: 2;
    }
    .app-img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
      object-position: center;
      z-index: 1;
    }
  }
  .publiceItem:hover .publiceItem-btn {
    display: block;
    opacity: 1;
  }
}
</style>


