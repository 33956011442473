<template>
  <footer>
    <div class="bottom_nav">
      <div class="item">
        {{pageTitle}}
      </div>
      <!-- <div class="item">
        企业名录
      </div> -->
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    pageTitle() {
        return this.$store.state.config.top || '南京智能计算中心生态应用平台';
    }
  },
}
</script>

<style lang="scss" scoped>
footer {
  width: 1920px;
  height: 65px;
  background: #02142c;
  box-sizing: border-box;
  padding: 0 240px;
  bottom: 0px;
  .bottom_nav {
    height: 100%;
    display: flex;
    color: #fff;
    align-items: center;
    font-size: 15px;
    .item {
      padding: 0 15px;
      /* &:first-child {
        border-right: 2px solid #fff;
      } */
    }
  }
}
</style>